<template>
	<div class="animated fadeIn">
		<div class="table-responsive">
			<table class="table table-hover table-striped">
				<thead>
					<tr>
						<th
							class="border-top-0 pointer"
							@click="sortByField('number')">
							{{ translate('week') }}
							<sort field="number" />
						</th>
						<th class="border-top-0">
							{{ translate('period') }}
						</th>
						<th class="border-top-0">
							{{ translate('amount') }}
						</th>
						<th class="border-top-0 text-center">
							{{ translate('options') }}
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index">
						<td class="align-middle">
							{{ item.attributes.week_number }}
						</td>
						<td class="align-middle">
							{{ $moment(item.attributes.from_date.date).format(dateFormat) }} - {{ $moment(item.attributes.to_date.date).format(dateFormat) }}
						</td>
						<td class="align-middle">
							{{ item.attributes.amount }}
						</td>
						<td class="align-middle text-center">
							<b-button
								v-b-tooltip.hover
								:title="translate('view_details')"
								variant="success"
								class="bg-primary-alt"
								@click="$router.push({ name: 'WeeklyCommissions', params: { week_id: item.attributes.week_number }, query: { user_id: $route.params.distributorId } });">
								<i
									class="fa fa-eye"
									aria-hidden="true" />
							</b-button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import { PAGINATION } from '@/settings/RequestReply';
import { Report as CO, Grids, Tooltip } from '@/translations';
import { MDY_FORMAT } from '@/settings/Dates';
import Commissions from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import sort from '@/components/SortArrow';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'Commissions',
	components: { sort },
	messages: [CO, Grids, Tooltip],
	data() {
		return {
			commissions: new Commissions(),
			alert: new this.$Alert(),
			dateFormat: MDY_FORMAT,
			endpoint,
		};
	},
	computed: {
		loading() {
			return !!this.commissions.data.loading;
		},
		pagination() {
			return this.commissions.data.pagination;
		},
		data() {
			try {
				const { data } = this.commissions.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
	},
	mounted() {
		this.commissions.getDistributorsCommissions(this.$route.params.distributorId);
	},
	methods: {
		getDataFiltered(page) {
			if (typeof page === 'object') {
				this.filters = { ...this.filters, ...page };
			}
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.commissions.getDistributorsCommissions(this.$route.params.distributorId, options);
		},
	},
};
</script>
